/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 44번째 로그에서는 Form S-1, 컨테이너 런타임 rkt 아카이브, 슈프리마 개인정보 유출, 레일스5 업그레이드 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.p, null, "준비중"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/43/"
  }, "stdout_043.log: 갤럭시 노트 10, 테슬라 모델 3, Go 언어 입문, 테라폼 삽질 공유회 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mediaelementjs.com/"
  }, "MediaElement.js - HTML5 video and audio unification framework")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rebuild.fm/"
  }, "Rebuild - Podcast by Tatsuhiko Miyagawa")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/transcribe/"
  }, "Amazon Transcribe – 자동 음성 인식 – AWS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/translate/docs/"
  }, "Cloud Translation documentation  |  Cloud Translation  |  Google Cloud")), "\n"), "\n", React.createElement(_components.h2, null, "WeWork, Cloudflare Form S-1 제출"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sec.gov/Archives/edgar/data/1533523/000119312519220499/d781982ds1.htm"
  }, "Form S-1 - The We Company")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sec.gov/Archives/edgar/data/1477333/000119312519222176/d735023ds1.htm"
  }, "Form S-1 Cloudflare, Inc.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "dart.fss.or.kr/dsaf001/main.do?rcpNo=20190704000160"
  }, "Dart - 플리토/증권신고서(지분증권)/2019.07.04")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/349031"
  }, "캐리소프트, 상장 철회...“연내 재도전” | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/stock/view/2018/12/802878/"
  }, "단독 - 먹구름 낀 IPO…1조클럽 ", React.createElement(_components.code, null, "우아한형제들·야놀자"), " 내년 IPO 포기 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.co.kr/view/?no=20190820202914"
  }, "소프트캠프, 코스닥 이전 상장 추진 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://brunch.co.kr/@banksalad/320"
  }, "투자 사전 - 스팩(SPAC)이란?")), "\n"), "\n", React.createElement(_components.h2, null, "컨테이너 런타임 rkt 프로젝트 아카이브"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/blog/2019/08/16/cncf-archives-the-rkt-project/"
  }, "CNCF Archives the rkt Project - Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.opencontainers.org/"
  }, "Home - Open Containers Initiative")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/Docker-Seoul/"
  }, "Docker Seoul (Seoul, Korea (South)) | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coreos.com/"
  }, "Open source, containers, and Kubernetes | CoreOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/etcd-io/etcd"
  }, "etcd-io/etcd: Distributed reliable key-value store for the most critical data of a distributed system")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.redhat.com/en/about/press-releases/red-hat-acquire-coreos-expanding-its-kubernetes-and-containers-leadership"
  }, "Red Hat to Acquire CoreOS, Expanding its Kubernetes and Containers Leadership")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/07/09/ibm-closes-red-hat-acquisition-for-34-billion/"
  }, "IBM closes Red Hat acquisition for $34 billion | TechCrunch")), "\n"), "\n", React.createElement(_components.h2, null, "슈프리마 개인정보 유출"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.boannews.com/media/view.asp?idx=82285"
  }, "23기가에 달하는 개인 식별 정보와 생체 정보 노출시킨 슈프리마")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elastic.co/kr/products/elasticsearch"
  }, "Elasticsearch: RESTful, 분산형 검색 및 분석 | Elastic")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opendistro.github.io/for-elasticsearch/"
  }, "Open Distro for Elasticsearch | Open Distro")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elastic.co/blog/security-for-elasticsearch-is-now-free"
  }, "Security for Elasticsearch is now free | Elastic Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20190625115900008"
  }, "슈프리마아이디, 증권신고서 제출…8월 코스닥 상장 추진 | 연합뉴스")), "\n"), "\n", React.createElement(_components.h2, null, "당근마켓 레일스 5 업그레이드"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/bigquery/docs/reference/legacy-sql"
  }, "Legacy SQL 함수 및 연산자  |  BigQuery  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mperham/sidekiq"
  }, "mperham/sidekiq: Simple, efficient background processing for Ruby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://guides.rubyonrails.org/v4.2.2/active_job_basics.html"
  }, "Active Job Basics — Ruby on Rails Guides")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/brandonhilkert/sucker_punch"
  }, "brandonhilkert/sucker_punch: Sucker Punch is a Ruby asynchronous processing library")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.datadoghq.com/logs/"
  }, "Log Management")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://weblog.rubyonrails.org/2019/8/15/Rails-6-0-final-release/"
  }, "Rails 6.0: Action Mailbox, Action Text, Multiple DBs, Parallel Testing, Webpacker by default, and Zeitwerk | Riding Rails")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019/presentations/riseshia.html#apr20"
  }, "Cleaning up a huge ruby application - RubyKaigi 2019")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
